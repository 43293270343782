import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw, RouterOptions} from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: 'index',
        component: () => import(/* webpackChunkName: "indexPage" */ '../views/home/index.vue')
    },
    {
        path: '/engineer',
        name: 'engineer',
        component: () => import(/* webpackChunkName: "engineerPage" */ '../views/engineer/index.vue')
    },
    {
        path: '/engineerList',
        name: 'engineerList',
        component: () => import(/* webpackChunkName: "engineerList" */ '../views/engineer/list.vue')
    },
    {
        // 匹配未定义的路由，跳转回首页
        path: '/:pathMatch(.*)',
        name: 'redirectToHome',
        redirect: '/',
    }
].filter(e => process.env.NODE_ENV === 'development' ? true : e.name !== 'engineerList')

const router = createRouter(<RouterOptions>{
    routes,
    history: createWebHashHistory(),
})

export default router
